<template >
  <div class="about">
    <div class="home_video soutien" style="padding:20px;">
      <div class="centered">
        <div class="ratio ratio_16_9">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/Aztq5d7RuGY?rel=0&autoplay=0&loop=1&showinfo=0&color=white&iv_load_policy=3"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
    <v-container :style="'padding-bottom:'+((isMobile)?0:270)+'px;'">
      <div class="article_content">
        <h1 class="styloText">Motion vidéo et UI animés</h1>
        <br />
        <p class="styloText first_paragraph">
          Que ce soit pour véhiculer un concept, une image ou simplement pour améliorer la qualité perçue d'un produit,
          l'animation est un support devenu presque incontournable offrant la visibilité recquise sur nombre de canaux de communication.
          Du plus petit élément d'interface jusqu'au clip vidéo,
          <b
            class="surligneur"
          >je conçois et réalise des supports animés sur mesure.</b>
        </p>
        <br />
        <Onscreen :moveY="0">
          <h2 pa-10 class="styloText">:: Éléments d'interface animés</h2>
        </Onscreen>
        <v-layout row>
          <v-flex md6 pa-10>
            <Onscreen :moveY="0">
              <div>
                <div class="block_image">
                  <lottie
                    :options="hamburger"
                    v-on:animCreated="handleAnimation"
                    width="80"
                    height="80"
                    type="sized"
                    value="angry"
                    :loop="false"
                    style="margin:0;"
                    :dynamic="true"
                    :states="2"
                    :state="0"
                    ratio="xMidYMid meet"
                  />
                </div>
                <h2 class="styloText">Éléments interactifs</h2>
                <p class="styloText">
                  Certains éléments d'interface participent gradement à la qualité perçue de votre produit.
                  La conception d'animations légères et fluides en SVG ou directement en stylesheet est un vrai plus pour vos interfaces web et mobile.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:121)" :delay="500">
              <div>
                <div class="block_image">
                  <lottie
                    :options="statics"
                    v-on:animCreated="handleAnimation"
                    width="100"
                    height="100"
                    type="sized"
                    value="angry"
                    :autoplay="false"
                    :loop="false"
                    style="margin:0;"
                    ratio="xMidYMid meet"
                  />
                </div>
                <h2 class="styloText">Éléments statiques</h2>
                <p class="styloText">
                  Que serait l'attente de Deliveroo sans le Wok faisant sauter des legumes ?
                  Dans vos parcours utilisateurs ces écrans statiques jouent parfois un rôle important
                  seulement le taux de décrochage cognitif des utilisateurs face à l'attente est d'un maximum de 3 secondes
                  les boucles d'animations sur ces pages statiques montrent aux utilisateurs qu'une action est en cours.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:0)" :delay="500">
              <div>
                <div class="block_image">
                  <lottie
                    :options="dynamic"
                    v-on:animCreated="handleAnimation"
                    width="128"
                    height="128"
                    type="sized"
                    :autoplay="false"
                    :loop="true"
                    style="margin:0;"
                    :frame="$store.state.offsetTop"
                    ratio="xMidYMid meet"
                  />
                </div>
                <h2 class="styloText">Éléments dynamiques</h2>
                <p class="styloText">
                  Pour rythmer vos pages de contenus et entrainer les utilisateurs à aller toujours plus loin dans vos pages,
                  les élèments dynamiques tel que le parallax créent une inertie dédiée à améliorer l'effisciences de pages statiques.
                  En web nous aimons que ce soit lèger c'est pourquoi nous écrivons vos scénarios d'événements sur mesure et en js natif pour le web.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:200)" :delay="500">
              <div>
                <div class="block_image">
                  <lottie
                    :options="system"
                    v-on:animCreated="handleAnimation"
                    width="100%"
                    height="120"
                    type="sized"
                    value="angry"
                    :autoplay="false"
                    :loop="false"
                    style="margin:0;"
                    ratio="xMidYMid meet"
                  />
                </div>
                <h2 class="styloText">Motion Design System</h2>
                <p class="styloText">
                  Au même titre que le Design system, l'écriture de motion deesign system est un réel atout effiscient pour transmettre et décliner
                  vos supports de communication animés. Ne pas ré-inventer la roue à chaque projet c'est s'assurer d'un gain de temps et de coûts
                  pour concentrer vos budjets sur les messages précis à véhiculer. Du simple habillage jusqu'a l'écriture de système d'animation complexes
                  dédié et optimisé sur mesure pour la marque ou service.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:0)" :delay="500">
              <div>
                <div class="block_image">
                  <lottie
                    :options="compatibility"
                    v-on:animCreated="handleAnimation"
                    width="100%"
                    height="170"
                    type="sized"
                    value="angry"
                    :autoplay="false"
                    :loop="false"
                    style="margin:0;"
                    ratio="xMidYMid meet"
                  />
                </div>
                <h2 class="styloText">Compatibilité</h2>
                <p class="styloText">
                  Pour assurer fluidité et compatibilité Web et mobile nous concevons les animations SVG avec After Effec, Body Movin et Lottie
                  D'autres librairies légères tel que TIMELINEMAX, SNAP ou Raphaël assurent également une intégration propre,
                  fluide tout en minimisant les contraintes et la création de timelines dynamiques.
                </p>
              </div>
            </Onscreen>
          </v-flex>
        </v-layout>
      </div>
    </v-container>
    <v-container>
      <div class="article_content">
        <Onscreen :moveY="0">
          <h2 pa-10 class="styloText">:: Process de conception vidéo</h2>
        </Onscreen>
        <v-layout row>
          <v-flex md6 pa-10>
            <Onscreen :moveY="0">
              <div>
                <h2 class="styloText">StoryTelling</h2>
                <p class="styloText">
                  Nous attachons la plus grande importance au message et ne travaillons jamais sans collecter un maximum d'informations
                  sur le produit et avoir établi une trame, une histoire et un schéma précis du contenu à élaborer.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:121)" :delay="500">
              <div>
                <h2 class="styloText">StoryBoarding</h2>
                <p class="styloText">
                  Nous traduisons l'histoire sous forme de storyboard pour qu'il n'y ait pas de mauvaises surprises
                  et nous assurer d'appoorter une réponse concrète à l'objectif du contenu.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:0)" :delay="500">
              <div>
                <h2 class="styloText">Test utilisateurs</h2>
                <p class="styloText">
                  Des tests neutres et externes destinés à éprouver les storyboard
                  permet d'obtenir un retour direct sur l'éffiscience de celui-ci et de le rectifier avant sa mise en oeuvre.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:121)" :delay="500">
              <div>
                <h2 class="styloText">Optimisation du format</h2>
                <p class="styloText">
                  Lors de divers expériences de réalisation de contenus animés pour du brand content nous avons constaté des limites
                  cognitives claires vis à vis des spectateurs c'est pourquoi nous nous engageons à traduire le message sur un format
                  court ne dépassant jamais 1 minute. Sur Facebook le décrochage cognitif d'un spectateur est de 18,2 secondes, c'est au regard de ces statistiques
                  fiables que nous optimisons les messages indispensables dans les 18 premières secondes.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:0)" :delay="500">
              <div>
                <h2 class="styloText">Design et character design</h2>
                <p class="styloText">
                  Le storyboard papier finalisé, nous élaborons ou finalisons les érans clés de l'animation d'un point de vue graphique.
                  Dans le cas ou le storyboard intègre des personnas, nous créons des character design spécifiques et propre à l'image de marque avant de les animer.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:121)" :delay="500">
              <div>
                <h2 class="styloText">Réalisation</h2>
                <p class="styloText">
                  Les designs et storyboard établis, nous réalisons les contenus aux formats spécifiques liés aux plateforme de diffusion.
                  La réalisation d'animation est une pratique relativement complexe,
                  c'est poour cette raison que nous nous assurons de répondre parfaitement au sujet avant de débuter uun projet animé
                  pour en limiter les coûts au maximum.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:0)" :delay="500">
              <div>
                <h2 class="styloText">Sound Design</h2>
                <p class="styloText">
                  N'étant pas spécialiste en composition, sound design et doublages voix off
                  nous vous orientons vers des compositeurs produisant des contenus originaux et de qualité.
                  Le bon son et la bonne voix c'est aussi important que l'animation elle même.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <!-- <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:121)" :delay="500">
              <div>
                <h2 class="styloText">Traduction et déclinaisons</h2>
                <p class="styloText">
                  La déclinaison de contenus texte animé est souovent un enjeu de taille.
                  L'écriture de systemes d'animations à la fois complexes et simple à décliner est une spécialité.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:0)" :delay="500">
              <div>
                <h2 class="styloText">Tarifs = petits prix</h2>
                <p class="styloText">
                  Le portage salarial est un outil parfait pour limiter votre budget.
                  Une intervention de Bige sur un projet intra muros et en portage salarial vous évite de recruter une ressource à temps plein
                  pour des projets non récurents comme de l'animation et est bien plus économique que de faire appel à une agence entrapinant
                  des frais de déplacement et ne se joignant pas à votre équipe créative pour élaborer les contenus.
                </p>
              </div>
            </Onscreen>
          </v-flex>-->
        </v-layout>
      </div>
    </v-container>
  </div>
</template>
<script>
import Parallax from "@/components/UI/Parallax";
import Onscreen from "@/components/UI/OnScreen";

import headerDev from "@/assets/images/HP/header_developpement.jpg";
const Lottie = () => import("@/components/LottieMotion.vue");
import * as Hamburger from "@/assets/motions/UI/hamburger.json";
import * as Wait from "@/assets/motions/UI/wait.json";
import * as System from "@/assets/motions/UI/motion_system.json";
import * as Compatibility from "@/assets/motions/UI/compatibility.json";
import * as Dynamic from "@/assets/motions/UI/dynamic.json";

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { Parallax, Onscreen, Lottie },
  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
        default:
          return false;
      }
    },
    fixedTab: {
      get: function () {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return false;
          case "sm":
            return true;
          case "md":
            return true;
          case "lg":
            return true;
          case "xl":
            return true;
          default:
            return true;
        }
      },
    },
  },
  data() {
    return {
      headerDev: headerDev,
      hamburger: {
        animationData: Hamburger.default,
        loop: false,
        autoplay: false,
      },
      statics: {
        animationData: Wait.default,
        loop: true,
        autoplay: true,
      },
      dynamic: {
        animationData: Dynamic.default,
        loop: true,
        autoplay: false,
      },
      system: {
        animationData: System.default,
        loop: true,
        autoplay: true,
      },
      compatibility: {
        animationData: Compatibility.default,
        loop: true,
        autoplay: true,
      },
    };
  },
  methods: {
    handleAnimation: function () {},
  },
};
</script>
<style scoped>
.block_image {
  text-align: left;
}
.home_video {
  width: 100%;
  /* max-height: 40vh; */
  position: relative;
}
.home_video .centered {
  top: 0vh;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  max-width: 560px;
  display: block;
  position: relative;
}
</style>